
.loginForm {
    position: absolute;
    width: 100vw;
    height: 200vh;
    background: url('../img/terminal.webp') no-repeat center;
    background-size: cover;
}

h3.text-primary {
    color: #2a5da6 !important;
    font-weight: bold;
}
.overlord {
    position: absolute;
    width: 100vw;
    height: 200vh;
    background: rgba(255, 255, 255, 0.7);
}
.logo, .logo-en {
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    color: #2a5da6;
    display: grid;
    padding-left: 15px;
    letter-spacing: -0.5px;
}
.logo-en {
    letter-spacing: 1px;
}
.logo > span, .logo-en > span {
    font-size: 52px;
    text-align: left;
    color: #e32d31;
    line-height: 40px;
    align-content: center;
    letter-spacing: 11px;
}
.logo-en > span {
    letter-spacing: 7px;
}

.img-logo{
    width:100px;
    display: block;
    float: left;
    margin-top:10px
}
.map {
    width: 100%;
}

.lang{
    font-size: 18px;
    cursor: pointer;

}

.lang.active{
    color:#e32d31
}

.dublFont {
    height:2em !important;
    width:2em !important;
}
.text-right {
    float:right;
    font-size:18px;
    color:#6c757d;
}
.text-right_ {
    float: right;


}

th.typeCol {
    min-width: 65px;
}

.bigIcon svg{

    width: 24px;
    margin-top: -14px;
    margin-left: -3px;

}

.btn-block_ {
    display:block !important;
}

.iconSvg {

    display: inline-block;
    height: 1em;
    width: 1em;
    visibility: inherit;
    fill: currentcolor;
    box-sizing: content-box;
    margin-top:-18px

}

.iconSvg-success path {
    --bs-bg-opacity: 1;
    fill: rgba(var(--bs-success-rgb),var(--bs-bg-opacity)) !important;
}

.linkTd {
    text-decoration: underline;
    cursor:pointer;
    color: var(--bs-link-color) !important;
}
.linkTd:hover {
    color: var(--bs-link-hover-color) !important;
}

.pointer {
    cursor:pointer !important;
}
.page-link:focus {
    background-color: var(--bs-pagination-bg) !important;
    box-shadow: none !important;
}

.overblur {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(255,255,255,0.5);
    z-index: 100;
}

.baner-main {
    width: 100%;
}