.myLink {
    color:white;
    text-decoration: none;
}
.myLink:hover {
    color:white;

}
.myBtn {
    margin-right: 15px;
}
