.position {
    position:relative;
}
.autocomplet {
    position:absolute;
    left:0;
    top:50px;
    width:100%;
    background:white;
    list-style:none;
    margin:0;
    padding:0;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.15);
    z-index:10;
    max-height:240px;
    height:auto;
    overflow:auto;
}

.autocomplet__item {
    padding:10px;
    cursor:pointer;
}
.autocomplet__item:hover {
    background: rgb(200,200,200);

}
