* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --bs-body-font-size: 12px;
}


.containerCol {
 max-width:200px;
}
.container-info.list-group {
  font-size:16px;
}
.container-info.list-group > div {
  position:relative;
}
.card-body .alert{
  font-size: 16px;
}
.container-info.list-group > div > span {
  font-weight: bold;
  position: absolute;
  left: 165px;
}
.tr-hide {
  display:none;
}
.tr-hide.activeTr{
  display:table-row;
}
td.photo{
  max-width:300px;
}
@media (max-width: 991px){
  .lang-mob.show {
    display:block !important;
  }
  .lang-mob span.nav-link {
    display:inline !important;
    padding: 5px 15px;
  }
  .lang-mob span.nav-link:first-child {

    padding-left: 0px
  }
}